/* Box sizing & margin rules */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* !! ADD TABINDEX to '#' HREFs !! | Solves cmd + F slow scrolling */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  font-size: 16px;
  min-height: 100vh;
  line-height: 1.5;
}

/* Remove list styles on ul, ol element */
ul,
ol {
  list-style: none;
}

/* remove underline from 'a' elements */
a {
  text-decoration: none;
  color: inherit;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  /* display: block; */
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* ------------------------------------------------------------------------ */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,600;1,100&display=swap");

html {
  background-color: black;
}

body {
  font-family: "Noto Sans", Arial, sans-serif;
}

img {
  display: inline;
}

p {
  display: inline;
}

main {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("https://media.istockphoto.com/id/1035676256/photo/background-of-galaxy-and-stars.jpg?s=612x612&w=0&k=20&c=dh7eWJ6ovqnQZ9QwQQlq2wxqmAR7mgRlQTgaIylgBwc=");
  background-repeat: repeat;
  background-size: 100vw;
  color: #fefefe;
  text-shadow: 0 0 10px rgb(255, 255, 255);
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1;
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 7vw;
  background: rgba(22, 181, 225, 0.3)
    url("https://media.istockphoto.com/id/472809828/photo/deep-space.jpg?b=1&s=170667a&w=0&k=20&c=4fgTwctcStOLy0XiXl-5H3MqTgSx-vALDr9rsSt4g0o=");
}

.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background: center center
    url("https://media-s3-us-east-1.ceros.com/media-investment-projects/images/2022/09/09/a2743400a386ad50ebd205bb4a647518/grid.jpg");
  /* background: url("https://image.shutterstock.com/image-vector/vector-perspective-curved-grid-detailed-260nw-1570333171.jpg"); */
  background-size: 110% 100%;
  filter: invert() hue-rotate(190deg) blur(1px)
    drop-shadow(0 6px 10px rgba(22, 181, 225, 0.3));
  opacity: 0.5;
}

.poke-logo {
  transform: scale(.8);
}

.burger-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 25px;
}

.burger-icon div {
  width: 35px;
  height: 4px;
  background-color: #14f3ffd8;
  border-radius: 30%;
  box-shadow: 0 0 8px rgba(22, 181, 225, 1);
}

.burger-icon:focus .burger-2{
    transform: translate(-8px, 0) rotate(50deg) scale(0.8);
    transition: all ease-in-out .7s;
  }
  
  .burger-icon:focus .burger-3 {
    transform: translate(9px, -11px) rotate(-50deg) scale(0.8);
    transition: all ease-in-out .7s;
  }


.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  border-radius: 0 0 35px 35px;
  background: rgb(12, 87, 108);
  background: linear-gradient(
    180deg,
    rgba(20, 66, 99, 0.6) 0%,
    rgba(12, 87, 108, 0.2) 5%,
    rgba(12, 87, 108, 0) 100%
  );
}

.title-box {
  display: flex;
  position: sticky;
  justify-content: center;
  align-items: center;
  width: 300px;
  border-radius: 6px 50px;
  box-shadow: 0 0 5px rgba(22, 181, 225, 1);
  background: radial-gradient(
    circle,
    rgba(22, 181, 225, 1) 0%,
    rgba(22, 181, 225, 0.8) 60%,
    rgba(22, 181, 225, 0.6) 100%
  );
  /* color: black; */
  text-shadow: 1px 2px 5px rgb(0, 109, 139);
  font-size: 1.4em;
}

.title-box h1 {
  font-weight: 400;
  /* font-style: italic; */
  letter-spacing: -5px;
}

.index {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.pages-index {
  padding: 3px;
  font-size: 1.2em;
}

.pokemons {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 0 2vw;
}

.pkCards {
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px;
  padding: 10px;
  width: 250px;
  height: 300px;
  border: 1px solid gray;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.6) inset;
  color: #fefefe;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
  overflow: hidden;
  position: relative;
}

.thumbnails {
  width: 130px;
  height: 130px;
  margin: 5px;
}

.top-info {
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.types {
  display: flex;
  margin: 0 0 10px 0;
}

.type-box {
  width: 75px;
  margin: 3px;
  border-radius: 20px;
  text-align: center;
  text-shadow: 0 0 5px rgb(0 0 0 / 75%);
}

.info-button {
  margin-bottom: 5px;
  width: 50%;
  border: none;
  background: none;
  color: #fefefe;
  text-shadow: 0 0 5px rgba(255, 255, 255, 1);
  z-index: 5;
}

.text-hover {
  transition: all 0.3s ease-in-out;
}

.text-hover:hover {
  cursor: pointer;
  text-shadow: 0 0 1px rgba(255, 255, 255, 1);
  transform: scale(1.2);
}

.stats {
  display: flex;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.6) inset;
  text-shadow: 0 0 5px rgba(255, 255, 255, 1);
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(7, 7, 7, 0.9);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
}

@keyframes stats-grow {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}
@keyframes stats-shrink {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

.stats-expanded {
  top: 0;
  animation: stats-grow ease-in-out 1s;
}

.stats-contracted {
  top: 100%;
  animation: stats-shrink ease-in-out 1s;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.points {
  display: flex;
  align-items: center;
}

.dots {
  margin: 5px 1px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: rgb(22, 181, 225);
  background: radial-gradient(
    circle,
    rgba(22, 181, 225, 1) 0%,
    rgba(22, 181, 225, 0.8) 50%,
    rgba(22, 181, 225, 0) 100%
  );
  box-shadow: 0 0 5px rgba(22, 181, 225, 0.5);
}

.dots-gray {
  margin: 5px 1px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  border: 1px solid rgba(22, 181, 225, 0.5);
  box-shadow: 0 0 5px rgba(22, 181, 225, 0.5);
}

.footer {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  font-size: 0.6em;
}

/* pokemon type box colors */

.Grass {
  background-color: #91be19;
  border: 2px solid #b5f000;
  box-shadow: 0 0 0.25em 0.03em #91be19;
}
.Poison {
  background-color: #bd78bd;
  border: 2px solid #d08cd1;
  box-shadow: 0 0 0.25em 0.03em #bd78bd;
}
.Fire {
  background-color: #ff3700;
  border: 2px solid #ff6900;
  box-shadow: 0 0 0.25em 0.03em #ff3700;
}
.Dragon {
  background-color: #3c64c8;
  border: 2px solid #5078dc;
  box-shadow: 0 0 0.25em 0.03em #3c64c8;
}
.Flying {
  background-color: #79bcd7;
  border: 2px solid #78dcff;
  box-shadow: 0 0 0.25em 0.03em #79bcd7;
}
.Water {
  background-color: #0094e5;
  border: 2px solid #14b9ff;
  box-shadow: 0 0 0.25em 0.03em #0094e5;
}
.Bug {
  background-color: #32b432;
  border: 2px solid #46c846;
  box-shadow: 0 0 0.25em 0.03em #32b432;
}
.Electric {
  background-color: #e4b700;
  border: 2px solid #ffe100;
  box-shadow: 0 0 0.25em 0.03em #e4b700;
}
.Ground {
  background-color: #cca142;
  border: 2px solid #fac85a;
  box-shadow: 0 0 0.25em 0.03em #cca142;
}
.Normal {
  background-color: #a0a0a0;
  border: 2px solid #dcdcdc;
  box-shadow: 0 0 0.25em 0.03em #a0a0a0;
}
.Fairy {
  background-color: #ff7eb8;
  border: 2px solid #ffafdc;
  box-shadow: 0 0 0.25em 0.03em #ff7eb8;
}
.Dark {
  background-color: #646464;
  border: 2px solid #787878;
  box-shadow: 0 0 0.25em 0.03em #646464;
}
.Psychic {
  background-color: #dc78c8;
  border: 2px solid #f08cdc;
  box-shadow: 0 0 0.25em 0.03em #dc78c8;
}
.Ice {
  background-color: #00b7ee;
  border: 2px solid #14f5ff;
  box-shadow: 0 0 0.25em 0.03em #00b7ee;
}
.Fighting {
  background-color: #c85500;
  border: 2px solid #dc6900;
  box-shadow: 0 0 0.25em 0.03em #c85500;
}
.Steel {
  background-color: #96b4dc;
  border: 2px solid #aac8f0;
  box-shadow: 0 0 0.25em 0.03em #96b4dc;
}
.Rock {
  background-color: #a07850;
  border: 2px solid #b48c64;
  box-shadow: 0 0 0.25em 0.03em #a07850;
}
.Ghost {
  background-color: #8c78f0;
  border: 2px solid #a08cff;
  box-shadow: 0 0 0.25em 0.03em #8c78f0;
}
